class VideoPopup {
    static instance;

    static getInstance() {
        if (!VideoPopup.instance) {
            VideoPopup.instance = new VideoPopup();
        }
        return VideoPopup.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-video-popup.default', ($scope) => {
            let data = $scope.data('settings');

            let selector = `.elementor-element-${$scope.data('id')}`;
            TweenMax.set(selector + " .opal-video-popup-wrapper .play-circle-01", {
                rotation: 90,
                transformOrigin: "center"
            })

            TweenMax.set(selector + " .opal-video-popup-wrapper .play-circle-02", {
                rotation: -90,
                transformOrigin: "center"
            })

            TweenMax.set(selector + " .opal-video-popup-wrapper .play-perspective", {
                xPercent: -50,
                yPercent: -50,
                scale: .1,
                transformOrigin: "center",
                perspective: 2
            })

            TweenMax.set(selector + " .opal-video-popup-wrapper .play-video", {
                visibility: "hidden",
                opacity: 0,
            })

            TweenMax.set(selector + " .opal-video-popup-wrapper .play-triangle", {
                transformOrigin: "left center",
                transformStyle: "preserve-3d",
                rotationY: 10,
                scaleX: 2
            })

            let rotateTL = new TimelineMax({paused: true})
                .to(selector + " .opal-video-popup-wrapper .play-circle-01", .7, {
                    opacity: .1,
                    rotation: '+=360',
                    strokeDasharray: "456 456",
                    ease: Power1.easeInOut
                }, 0)
                .to(selector + " .opal-video-popup-wrapper .play-circle-02", .7, {
                    opacity: .1,
                    rotation: '-=360',
                    strokeDasharray: "411 411",
                    ease: Power1.easeInOut
                }, 0)

            let openTL = new TimelineMax({paused: true})
                .to(selector + " .opal-video-popup-wrapper .play-backdrop", 1, {
                    opacity: .95,
                    visibility: "visible",
                    ease: Power2.easeInOut
                }, 0)
                .to(selector + " .opal-video-popup-wrapper .play-close", 1, {
                    opacity: 1,
                    ease: Power2.easeInOut
                }, 0)
                .to(selector + " .opal-video-popup-wrapper .play-perspective", 1, {
                    //xPercent: 0,
                    scale: 1,
                    ease: Power2.easeInOut
                }, 0)
                .to(selector + " .opal-video-popup-wrapper .play-triangle", 1, {
                    scaleX: 1,
                    ease: ExpoScaleEase.config(2, 1, Power2.easeInOut)
                }, 0)
                .to(selector + " .opal-video-popup-wrapper .play-triangle", 1, {
                    rotationY: 0,
                    ease: ExpoScaleEase.config(10, .01, Power2.easeInOut)
                }, 0)
                .to(selector + " .opal-video-popup-wrapper .play-video", 1, {
                    visibility: "visible",
                    opacity: 1
                }, .5)


            let button = document.querySelector(selector + " .opal-video-popup-wrapper .play-button")
            let backdrop = document.querySelector(selector + " .opal-video-popup-wrapper .play-backdrop")
            let close = document.querySelector(selector + " .opal-video-popup-wrapper .play-close")
            button.addEventListener("mouseover", () => rotateTL.play())
            button.addEventListener("mouseleave", () => rotateTL.reverse())
            if(typeof data !== 'undefined'){
                if( data.video_type === 'magnific'){
                    this.actionMagnific($scope);
                }else{
                    button.addEventListener("click", (e) =>{
                        openTL.play();
                        e.preventDefault();

                    } )
                    backdrop.addEventListener("click", () => openTL.reverse())
                    close.addEventListener("click", e => {
                        e.stopPropagation();
                        openTL.reverse();
                    });
                }
            }

        });
    }

    actionMagnific($scope) {
        $scope.find('.play-button').magnificPopup({
            type: 'iframe',
            removalDelay: 500,
            midClick: true,
            closeBtnInside: true,
            callbacks: {
                beforeOpen: function () {
                    this.st.mainClass = this.st.el.attr('data-effect');
                }
            },
        });
    }

}

VideoPopup.getInstance();