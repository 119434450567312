class Elementor_Image_Gallery {
    static instance;

    static getInstance() {
        if (!Elementor_Image_Gallery.instance) {
            Elementor_Image_Gallery.instance = new Elementor_Image_Gallery();
        }
        return Elementor_Image_Gallery.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-image-gallery.default', ($scope) => {
            function GridLoaderFx(el, options) {
                this.el = el;
                this.items = this.el.querySelectorAll('.grid__item > .grid__link');
            }

            /**
             * Effects.
             */
            GridLoaderFx.prototype.effects = {
                'Ra' : {
                    origin   : '50% 0%',
                    animeOpts: {
                        duration  : 500,
                        easing    : 'easeOutBack',
                        delay     : function (t, i) {
                            return i * 100;
                        },
                        opacity   : {
                            value : [0, 1],
                            easing: 'linear'
                        },
                        translateY: [400, 0],
                        scaleY    : [
                            {
                                value      : [3, 0.6], delay: function (t, i) {
                                    return i * 100 + 120;
                                }, duration: 300, easing: 'easeOutExpo'
                            },
                            {value: [0.6, 1], duration: 1400, easing: 'easeOutElastic'}
                        ],
                        scaleX    : [
                            {
                                value      : [0.9, 1.05], delay: function (t, i) {
                                    return i * 100 + 120;
                                }, duration: 300, easing: 'easeOutExpo'
                            },
                            {value: [1.05, 1], duration: 1400, easing: 'easeOutElastic'}
                        ]
                    }
                },
                'Nut': {
                    revealer          : true,
                    revealerColor     : '#1d1d1d',
                    itemOverflowHidden: true,
                    animeRevealerOpts : {
                        easing    : 'easeOutCubic',
                        delay     : function (t, i) {
                            return i * 100;
                        },
                        translateX: [
                            {value: ['101%', '0%'], duration: 400},
                            {value: ['0%', '-101%'], duration: 400}
                        ]
                    },
                    animeOpts         : {
                        duration: 900,
                        easing  : 'easeOutCubic',
                        delay   : function (t, i) {
                            return 400 + i * 100;
                        },
                        opacity : {
                            value   : 1,
                            duration: 1,
                            easing  : 'linear'
                        },
                        scale   : [0.8, 1]
                    }
                },
                'Shu': {
                    lineDrawing         : true,
                    animeLineDrawingOpts: {
                        duration        : 800,
                        delay           : function (t, i) {
                            return i * 150;
                        },
                        easing          : 'easeInOutSine',
                        strokeDashoffset: [anime.setDashoffset, 0],
                        opacity         : [
                            {value: [0, 1]},
                            {value: [1, 0], duration: 200, easing: 'linear', delay: 500}
                        ]
                    },
                    animeOpts           : {
                        duration: 800,
                        easing  : [0.2, 1, 0.3, 1],
                        delay   : function (t, i) {
                            return i * 150 + 800;
                        },
                        opacity : {
                            value : [0, 1],
                            easing: 'linear'
                        },
                        scale   : [0.5, 1]
                    }
                }
            };
            GridLoaderFx.prototype._render = function (effect) {
                // Reset styles.
                this._resetStyles();
                var self           = this,
                    effectSettings = this.effects[effect],
                    animeOpts      = effectSettings.animeOpts
                if (effectSettings.perspective != undefined) {
                    [].slice.call(this.items).forEach(function (item) {
                        item.parentNode.style.WebkitPerspective = item.parentNode.style.perspective = effectSettings.perspective + 'px';
                    });
                }
                if (effectSettings.origin != undefined) {
                    [].slice.call(this.items).forEach(function (item) {
                        item.style.WebkitTransformOrigin = item.style.transformOrigin = effectSettings.origin;
                    });
                }
                if (effectSettings.lineDrawing != undefined) {
                    [].slice.call(this.items).forEach(function (item) {
                        // Create SVG.
                        var svg   = document.createElementNS('http://www.w3.org/2000/svg', 'svg'),
                            path  = document.createElementNS('http://www.w3.org/2000/svg', 'path'),
                            itemW = item.offsetWidth - 20,
                            itemH = item.offsetHeight - 20;
                        svg.setAttribute('width', itemW + 'px');
                        svg.setAttribute('height', itemH + 'px');
                        svg.setAttribute('viewBox', '0 0 ' + itemW + ' ' + itemH);
                        svg.setAttribute('class', 'grid__deco');
                        path.setAttribute('d', 'M0,0 l' + itemW + ',0 0,' + itemH + ' -' + itemW + ',0 0,-' + itemH);
                        path.setAttribute('stroke-dashoffset', anime.setDashoffset(path));
                        svg.appendChild(path);
                        item.parentNode.appendChild(svg);
                    });
                    var animeLineDrawingOpts = effectSettings.animeLineDrawingOpts;
                    animeLineDrawingOpts.targets = this.el.querySelectorAll('.grid__deco > path');
                    anime.remove(animeLineDrawingOpts.targets);
                    anime(animeLineDrawingOpts);
                }
                if (effectSettings.revealer != undefined) {
                    [].slice.call(this.items).forEach(function (item) {
                        var revealer = document.createElement('span');
                        revealer.className = 'grid__reveal';
                        if (effectSettings.revealerOrigin != undefined) {
                            revealer.style.transformOrigin = effectSettings.revealerOrigin;
                        }
                        if (effectSettings.revealerColor != undefined) {
                            revealer.style.backgroundColor = effectSettings.revealerColor;
                        }
                        item.parentNode.appendChild(revealer);
                    });
                    var animeRevealerOpts = effectSettings.animeRevealerOpts;
                    animeRevealerOpts.targets = this.el.querySelectorAll('.grid__reveal');
                    animeRevealerOpts.begin = function (obj) {
                        for (var i = 0, len = obj.animatables.length; i < len; ++i) {
                            obj.animatables[i].target.style.opacity = 1;
                        }
                    };
                    anime.remove(animeRevealerOpts.targets);
                    anime(animeRevealerOpts);
                }
                if (effectSettings.itemOverflowHidden) {
                    [].slice.call(this.items).forEach(function (item) {
                        item.parentNode.style.overflow = 'hidden';
                    });
                }
                animeOpts.targets = effectSettings.sortTargetsFn && typeof effectSettings.sortTargetsFn === 'function' ? [].slice.call(this.items).sort(effectSettings.sortTargetsFn) : this.items;
                anime.remove(animeOpts.targets);
                anime(animeOpts);
            };
            GridLoaderFx.prototype._resetStyles = function () {
                this.el.style.WebkitPerspective = this.el.style.perspective = 'none';
                [].slice.call(this.items).forEach(function (item) {
                    var gItem = item.parentNode;
                    item.style.opacity = 0;
                    item.style.WebkitTransformOrigin = item.style.transformOrigin = '50% 50%';
                    item.style.transform = 'none';
                    var svg = item.parentNode.querySelector('svg.grid__deco');
                    if (svg) {
                        gItem.removeChild(svg);
                    }
                    var revealer = item.parentNode.querySelector('.grid__reveal');
                    if (revealer) {
                        gItem.removeChild(revealer);
                    }
                    gItem.style.overflow = '';
                });
            };
            window.GridLoaderFx = GridLoaderFx;
            var body        = document.body,
                grids       = [].slice.call(document.querySelectorAll('.grid')),
                currentGrid = 0,
                // The GridLoaderFx instances.
                loaders     = [],
                loadingTimeout;

            function init2() {
                // Preload images
                imagesLoaded(body, function () {
                    // Show current grid.
                    grids[currentGrid].classList.remove('grid--hidden');
                    // Remove loading class from body
                });
            }

            function applyFx(grid, animate) {
                // Simulate loading grid to show the effect.
                clearTimeout(loadingTimeout);
                grids[currentGrid].classList.add('grid--loading');
                let a = new GridLoaderFx(grid);
                loadingTimeout = setTimeout(function () {
                    grids[currentGrid].classList.remove('grid--loading');
                    // Apply effect.
                    a._render(animate);
                }, 500);
            }

            init2();
            let $list = $scope.find('.column-item');
            $list.each((index, element) => {
                $(element).hoverdir({
                    speed: 1,
                });
            });
            elementorFrontend.waypoint($scope, function () {
                let data = $scope.data('settings');
                let animate = 'Shu';
                if (data != undefined) {
                    animate = data['gallery_animation'];
                }
                let $grid = $scope.find('.grid');
                applyFx($grid.get(0), animate);
            });
        });
    }
}

Elementor_Image_Gallery.getInstance();